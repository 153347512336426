import { useState, useEffect } from 'react';
import i18next from 'i18next';
import ISO6991 from 'iso-639-1';
import { t } from 'i18next';
import { defaultLocale, locales } from '../i18n/utils';
import config from '../../astro-i18next.config.mjs';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/UI/drawer";

interface LanguageMapping {
  [localeCode: string]: string;
}

interface ChangeLanguageProps {
  languageMapping?: LanguageMapping;
}

interface Routes {
  [locale: string]: Record<string, string>;
}

// Fallback languages if config is not available
const FALLBACK_LANGUAGES = ['en', 'pt', 'es'];
const DEFAULT_LANGUAGE = 'pt';

const ChangeLanguage = ({ languageMapping }: ChangeLanguageProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>(FALLBACK_LANGUAGES);
  const [currentLanguage, setCurrentLanguage] = useState<string>(DEFAULT_LANGUAGE);
  const [isInitialized, setIsInitialized] = useState(false);
  
  // Helper function to extract language from cookie
  const getLanguageFromCookie = (): string | null => {
    try {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('preferredLanguage='));

      if (cookieValue) {
        return cookieValue.split('=')[1];
      }
      return null;
    } catch (error) {
      console.error("Error reading language cookie:", error);
      return null;
    }
  };
  
  // Initialize language state from browser when component mounts
  useEffect(() => {
    // Get current language from URL or cookies
    const detectLanguage = () => {
      try {
        // Priority 1: Check URL path for language code
        const pathLang = extractLocaleFromPath(window.location.pathname);
        
        // Priority 2: Check i18next
        const i18nextLang = typeof i18next !== 'undefined' && i18next.language ? i18next.language : null;
        
        // Priority 3: Check cookie
        const cookieLang = getLanguageFromCookie();
        
        // Priority 4: Check browser language
        const browserLang = navigator.language.split('-')[0];
        
        // Get supported languages from config or i18next
        if (Array.isArray(locales) && locales.length > 0) {
          setSupportedLanguages([...locales]);
        } else if (typeof i18next !== 'undefined' && i18next.languages && i18next.languages.length > 0) {
          setSupportedLanguages([...i18next.languages]);
        }
        
        // Determine current language based on priorities
        let detectedLang = null;
        
        if (pathLang && (Array.isArray(locales) ? locales.includes(pathLang) : supportedLanguages.includes(pathLang))) {
          detectedLang = pathLang;
          console.log("Language detected from URL path:", pathLang);
        } else if (i18nextLang && (Array.isArray(locales) ? locales.includes(i18nextLang) : supportedLanguages.includes(i18nextLang))) {
          detectedLang = i18nextLang;
          console.log("Language detected from i18next:", i18nextLang);
        } else if (cookieLang && (Array.isArray(locales) ? locales.includes(cookieLang) : supportedLanguages.includes(cookieLang))) {
          detectedLang = cookieLang;
          console.log("Language detected from cookie:", cookieLang);
        } else if (browserLang && (Array.isArray(locales) ? locales.includes(browserLang) : supportedLanguages.includes(browserLang))) {
          detectedLang = browserLang;
          console.log("Language detected from browser:", browserLang);
        } else if (typeof defaultLocale === 'string') {
          detectedLang = defaultLocale;
          console.log("Using default locale:", defaultLocale);
        }
        
        if (detectedLang) {
          setCurrentLanguage(detectedLang);
        }
        
        setIsInitialized(true);
      } catch (error) {
        console.error("Error detecting language:", error);
        setIsInitialized(true);
      }
    };
    
    detectLanguage();
  }, []);
  
  // Utility functions ported from the Astro component
  function extractLocaleFromPath(path: string): string | null {
    const match = path.match(/^\/([a-z]{2})\//);
    return match && Array.isArray(locales) && locales.includes(match[1]) ? match[1] : null;
  }

  function removeLocaleFromPath(path: string): string {
    return path.replace(/^\/[a-z]{2}/, '');
  }

  function createInverseRouteMaps(routes: Routes) {
    const inverseMaps: Record<string, Record<string, string>> = {};
    
    if (routes) {
      Object.keys(routes).forEach(locale => {
        inverseMaps[locale] = {};
        
        Object.entries(routes[locale]).forEach(([originalPath, translatedPath]) => {
          if (typeof originalPath === 'string' && typeof translatedPath === 'string') {
            const originalWithSlash = originalPath.startsWith('/') ? originalPath : `/${originalPath}`;
            const translatedWithSlash = translatedPath.startsWith('/') ? translatedPath : `/${translatedPath}`;
            
            inverseMaps[locale][translatedWithSlash] = originalWithSlash;
          }
        });
      });
    }
    
    return inverseMaps;
  }

  // Creating inverse route maps during component rendering
  const inverseRouteMaps = createInverseRouteMaps(config?.routes as Routes || {});

  function getLanguagePath(lang: string): string {
    try {
      const pathname = window.location.pathname;
      const search = window.location.search;
      const currentLocale = extractLocaleFromPath(pathname);
      
      const params = new URLSearchParams(search);
      
      if (lang === defaultLocale) {
        if (currentLocale && currentLocale !== defaultLocale) {
          const pathWithoutLocale = removeLocaleFromPath(pathname);
          
          if (currentLocale in inverseRouteMaps) {
            for (const [translatedPath, originalPath] of Object.entries(inverseRouteMaps[currentLocale])) {
              if (pathWithoutLocale === translatedPath || pathWithoutLocale.startsWith(`${translatedPath}/`)) {
                const remainingPath = pathWithoutLocale.substring(translatedPath.length);
                
                const queryString = params.toString() ? `?${params.toString()}` : '';
                return `${originalPath}${remainingPath}${queryString}`;
              }
            }
          }
          
          const queryString = params.toString() ? `?${params.toString()}` : '';
          return `${pathWithoutLocale}${queryString}`;
        }
        
        const queryString = params.toString() ? `?${params.toString()}` : '';
        return pathname + queryString;
      }
      
      const queryString = params.toString() ? `?${params.toString()}` : '';
      
      let cleanPath = pathname;
      if (currentLocale) {
        cleanPath = removeLocaleFromPath(pathname);
      }
      
      if (!currentLocale || currentLocale === defaultLocale) {
        if (cleanPath === '/') {
          return `/${lang}/${queryString}`;
        }
        
        if (config?.routes && config.routes[lang]) {
          for (const [originalPath, translatedPath] of Object.entries(config.routes[lang])) {
            if (typeof originalPath === 'string' && typeof translatedPath === 'string') {
              const originalWithSlash = originalPath.startsWith('/') ? originalPath : `/${originalPath}`;
              const translatedWithSlash = translatedPath.startsWith('/') ? translatedPath : `/${translatedPath}`;
              
              if (cleanPath === originalWithSlash || cleanPath.startsWith(`${originalWithSlash}/`)) {
                const remainingPath = cleanPath.substring(originalWithSlash.length);
                return `/${lang}${translatedWithSlash}${remainingPath}${queryString}`;
              }
            }
          }
        }
      } else if (currentLocale !== defaultLocale && currentLocale !== lang) {
        let defaultPath = cleanPath;
        if (currentLocale in inverseRouteMaps) {
          for (const [translatedPath, originalPath] of Object.entries(inverseRouteMaps[currentLocale])) {
            if (cleanPath === translatedPath || cleanPath.startsWith(`${translatedPath}/`)) {
              const remainingPath = cleanPath.substring(translatedPath.length);
              defaultPath = `${originalPath}${remainingPath}`;
              break;
            }
          }
        }
        
        if (config?.routes && config.routes[lang]) {
          for (const [originalPath, translatedPath] of Object.entries(config.routes[lang])) {
            if (typeof originalPath === 'string' && typeof translatedPath === 'string') {
              const originalWithSlash = originalPath.startsWith('/') ? originalPath : `/${originalPath}`;
              const translatedWithSlash = translatedPath.startsWith('/') ? translatedPath : `/${translatedPath}`;
              
              if (defaultPath === originalWithSlash || defaultPath.startsWith(`${originalWithSlash}/`)) {
                const remainingPath = defaultPath.substring(originalWithSlash.length);
                return `/${lang}${translatedWithSlash}${remainingPath}${queryString}`;
              }
            }
          }
        }
      }
      
      return `/${lang}${cleanPath}${queryString}`;
    } catch (error) {
      console.error("Error generating language path:", error);
      return `/${lang}`;
    }
  }

  const setLanguageCookie = (lang: string) => {
    try {
      document.cookie = `preferredLanguage=${lang}; path=/; max-age=31536000; SameSite=Lax`;
    } catch (error) {
      console.error("Error setting language cookie:", error);
    }
  };

  const handleLanguageSelect = (lang: string) => {
    try {
      setLanguageCookie(lang);
      const newPath = getLanguagePath(lang);
      window.location.href = newPath;
      setIsOpen(false);
    } catch (error) {
      console.error("Error handling language selection:", error);
    }
  };

  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerTrigger asChild>
        <button 
          className="flex items-center p-2 border h-8 w-8 border-gray-300  rounded-full transition-all duration-300 hover:bg-gray-100 "
          aria-label="Change language"
        >
          <svg 
            className="w-4 h-4 text-gray-800" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="2" y1="12" x2="22" y2="12"></line>
            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
          </svg>
         
         
        </button>
      </DrawerTrigger>
      
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader>
            <DrawerTitle>
              {currentLanguage === 'pt' ? 'Alterar idioma' : 
               currentLanguage === 'es' ? 'Cambiar idioma' : 
               'Change language'}
            </DrawerTitle>
            <DrawerDescription>
              {currentLanguage === 'pt' ? 'Selecione o idioma de sua preferência' : 
               currentLanguage === 'es' ? 'Seleccione su idioma preferido' : 
               'Select your preferred language'}
            </DrawerDescription>
          </DrawerHeader>
          
          <div className="p-4">
            {!isInitialized ? (
              <div className="text-center py-4">
                {currentLanguage === 'pt' ? 'Carregando idiomas...' : 
                 currentLanguage === 'es' ? 'Cargando idiomas...' : 
                 'Loading languages...'}
              </div>
            ) : (
              <ul className="space-y-2">
                {supportedLanguages.map((lang) => {
                  try {
                    const nativeName = languageMapping?.[lang] || ISO6991.getNativeName(lang);
                    return (
                      <li key={lang}>
                        <button
                          onClick={() => handleLanguageSelect(lang)}
                          data-lang={lang}
                          className={`w-full text-left p-4 text-[#353434] text-sm rounded-lg hover:bg-gray-50 transition-colors ${
                            lang === currentLanguage ? "bg-gray-50 font-medium" : ""
                          }`}
                        >
                          {nativeName} {lang === currentLanguage && (
                            <span className="inline-block ml-2 text-green-600">✓</span>
                          )}
                        </button>
                      </li>
                    );
                  } catch (error) {
                    console.error(`Error rendering language ${lang}:`, error);
                    return (
                      <li key={lang}>
                        <button
                          onClick={() => handleLanguageSelect(lang)}
                          data-lang={lang}
                          className="w-full text-left p-4 text-[#353434] text-sm rounded-lg hover:bg-gray-50 transition-colors"
                        >
                          {lang.toUpperCase()}
                        </button>
                      </li>
                    );
                  }
                })}
              </ul>
            )}
          </div>
          
          <DrawerFooter>
            <DrawerClose asChild>
              <button className="w-full py-2 px-4 bg-gray-100 rounded-lg text-gray-800 hover:bg-gray-200 transition-colors">
                {currentLanguage === 'pt' ? 'Cancelar' : 
                 currentLanguage === 'es' ? 'Cancelar' : 
                 'Cancel'}
              </button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default ChangeLanguage;
